import General from "./tnew-scripts/General.js";
import EventsList from "./tnew-scripts/pages/tn-events-list.js";
import EventsDetail from "./tnew-scripts/pages/tn-events-detail.js";
import Basket from "./tnew-scripts/pages/tn-basket.js";
import Login from "./tnew-scripts/pages/tn-login.js";
import Register from "./tnew-scripts/pages/tn-register.js";
import SignUp from "./tnew-scripts/pages/tn-signup.js";
import Checkout from "./tnew-scripts/pages/tn-checkout.js";
import subNav from "./tnew-scripts/Subnav.js";
import AccountInfo from "./tnew-scripts/pages/tn-account-info.js";
import Memberships from "./tnew-scripts/pages/tn-memberships.js";
import Shipping from "./tnew-scripts/pages/tn-shipping.js";

document.addEventListener("DOMContentLoaded", () => {
    // new SpektrixLogin();
    console.log("tnew has loaded");
});
