import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Shipping {
    constructor() {
        this.initialize();
        this.shippingTitles();
    }

    initialize() {}

    shippingTitles() {
        // Get current page
        const shippingpage = document.querySelector(".tn-shipping-page");

        // Page Title
        const headingTitle = document.querySelector(".tn-heading");

        if (shippingpage) {
            headingTitle.textContent = "Update Shipping";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Shipping();
});
