import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Register {
    constructor() {
        this.initialize();
        this.registerTitles();
        this.wrapregisterButton();
    }

    initialize() {}

    registerTitles() {
        // Get current page
        const registerpage = document.querySelector(".tn-account-create-page");

        // Page Title
        const headingTitle = document.querySelector(".tn-heading ");

        if (registerpage) {
            headingTitle.textContent = "Register";
        }

        // Communication preferences
        const loginTitle = document.querySelector(
            ".tn-component-contact-permissions__header h2",
        );

        if (loginTitle) {
            loginTitle.textContent = "Communication preferences";
        }
    }

    wrapregisterButton() {
        const registerButtonElement = document.getElementById(
            "tn-account-register-button-register",
        );

        if (!registerButtonElement) {
            return;
        }

        const registerButtonContainer = domHelpers.wrapEach(
            registerButtonElement,
            {
                element: "div",
                className: "tn-basket-button-container",
            },
        );

        const tnPageBottom = document.querySelector(
            ".tn-account-create-page .tn-page-bottom",
        );

        const container = document.querySelector(".tn-basket-button-container");

        if (container) {
            container.append(tnPageBottom);
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Register();
});
