import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class EventsDetail {
    constructor() {
        this.initialize();
        this.eventsDetailTitles();
        this.wrapBasketButton();
        this.quantityTitle();
        this.moveSelectDates();
        this.addDecimalsPayWhatYouChoose();
    }

    initialize() {}

    wrapBasketButton() {
        const basketButtonElement = document.getElementById(
            "tn-add-to-cart-button",
        );

        if (!basketButtonElement) {
            return;
        }

        const basketButtonContainer = domHelpers.wrapEach(basketButtonElement, {
            element: "div",
            className: "tn-basket-button-container",
        });
    }

    eventsDetailTitles() {
        // Get current page
        const eventdetailpage = document.querySelector(
            ".tn-events-detail-page",
        );

        // Page Title
        const eventldetailTitle = document.querySelector(".tn-heading");

        if (eventdetailpage) {
            eventldetailTitle.textContent = "Tickets";
        }
    }

    quantityTitle() {
        const qtyTitle = document.querySelector(
            ".tn-ticket-selector__pricetype-zone-heading",
        );

        if (qtyTitle) {
            qtyTitle.textContent = "Select quantity";
        }
    }

    moveSelectDates() {
        // Move Dates Container
        const additionalDates = document.querySelector(
            ".tn-event-detail__additional-events-container",
        );

        const container = document.querySelector(
            ".tn-event-detail__performance-details-container",
        );

        if (container) {
            container.append(additionalDates);
        }

        // Change option title
        const selectTitle = document.querySelector(
            "#tn-additional-events-select-list-placeholder-option",
        );

        if (selectTitle) {
            selectTitle.textContent = "Choose an alternative date:";
        }
    }

    addDecimalsPayWhatYouChoose() {
        const customNumInputs = document.querySelectorAll(
            ".form-control.tn-ticket-selector__custom-amount-input",
        );

        if (!customNumInputs) {
            return false;
        }

        customNumInputs?.forEach((input) => {
            input.min = parseInt(input.min);
            input.step = 1;

            input?.addEventListener("input", () => {
                const value = parseInt(this.value);

                if (isNaN(value)) {
                    this.value = "";
                } else {
                    this.value = value; // Set the value to the integer part only
                }
            });
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new EventsDetail();
});
