import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Basket {
    constructor() {
        this.initialize();
        this.basketTitles();
        this.moveBasketTimer();
    }

    initialize() {}

    basketTitles() {
        // Get current page
        const basketpage = document.querySelector(".tn-cart-details-page");

        // Page Title
        const headingTitle = document.querySelector(".tn-heading");

        if (basketpage) {
            headingTitle.textContent = "Basket";
        }
    }

    moveBasketTimer() {
        // Move Dates Container
        const additionalDates = document.querySelector(".tn-timer");

        const container = document.querySelector(".tn-header-component");

        if (additionalDates) {
            container.prepend(additionalDates);
        }

        // Change option title
        const selectTitle = document.querySelector(
            "#tn-additional-events-select-list-placeholder-option",
        );

        if (selectTitle) {
            selectTitle.textContent = "Choose an alternative date:";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Basket();
});
