import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class SignUp {
    constructor() {
        this.initialize();
        this.eventsListTitles();
    }

    initialize() {}

    eventsListTitles() {
        // Get current page
        const Signuppage = document.querySelector(
            ".tn-account-create-brief-page",
        );

        // Page Title
        const headingTitle = document.querySelector(".tn-heading");

        if (Signuppage) {
            headingTitle.textContent = "Sign Up";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new SignUp();
});
