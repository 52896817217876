import { quantityInputWrap } from "../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../scripts/utils/domHelpers.js";

class subNav {
  constructor() {
      this.initialize();
      this.subHeader();
  }

  initialize() {}

  subHeader() {
    var subHead = "",
      main,
      classes,
      tabNav,
      woUrl;

    main = jQuery("main");
    tabNav = jQuery(".tn-area-nav");
    // woUrl = 'https://www.horniman.ac.uk/whats-on/';

    classes = {
      payment: "tn-payment-page",
      events: "tn-events-listing-page",
      upsell: "tn-events-detail-page", 
      cart: "tn-cart-details-page",
      confirmation: "tn-receipt-page",
      guest: "tn-cart-guest-page-content",
      gift: "tn-gift-certificate-default-page",
      mailing: "tn-account-create-brief-page",
      account: {
        login: "tn-account-login-page",
        create: "tn-account-create-page",
        interests: "tn-account-interests-page",
        update: "tn-account-update-page",
        membership: "tn-account-memberships-page",
        event: "tn-upcoming-events-page",
      },
    };

    if (main.hasClass(classes.payment)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li>Select Seats</li>
              <li>Basket</li>
              <li class="active">Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.upsell)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container"> 
            <ul>
              <li class="active">Select Seats</li>
              <li>Basket</li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.events)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li class="active">Select Seats</li>
              <li>Basket</li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.cart)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li>Select Seats</li>
              <li class="active">Basket</li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>`, 
      );
    } else if (main.hasClass(classes.account.login)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li>Select Seats</li>
              <li>Basket</li>
              <li class="active">Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.create)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li>Select Seats</li>
              <li>Basket</li>
              <li class="active">Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.update)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li class="active"><a href="/account/update">Account Info</a></li>
              <li><a href="/account/interests">Interests</a></li>
              <li><a href="/account/memberships">Memberships</a></li>
              <li><a href="/account/upcomingevents">Upcoming Events</a></li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.membership)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li><a href="/account/update">Account Info</a></li>
              <li><a href="/account/interests">Interests</a></li>
              <li class="active"><a href="/account/memberships">Memberships</a></li>
              <li><a href="/account/upcomingevents">Upcoming Events</a></li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.event)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li><a href="/account/update">Account Info</a></li>
              <li><a href="/account/interests">Interests</a></li>
              <li><a href="/account/memberships">Memberships</a></li>
              <li class="active"><a href="/account/upcomingevents">Upcoming Events</a></li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.gift)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li class="active">Gift</a></li>
              <li>Basket</li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.interests)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li><a href="/account/update">Account Info</a></li>
              <li class="active"><a href="/account/interests">Interests</a></li>
              <li><a href="/account/memberships">Memberships</a></li>
              <li><a href="/account/upcomingevents">Upcoming Events</a></li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.account.donation)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li class="active">Donate</li>
              <li>Account Info</li> 
              <li>Basket</li>
            </ul>
          </div>
        </div>`,
      );
    } else if (main.hasClass(classes.guest)) {
      subHead = jQuery(
        `<div class="subhead">
          <div class="container">
            <ul>
              <li>Your Visit</li>
              <li>Shopping basket</li>
              <li>Checkout</li>
            </ul>
          </div>
        </div>`,
      );
    }

    var jQuerysubHead = jQuery(subHead);

    jQuery(".tn-heading-container").after(jQuerysubHead);
  }
}

document.addEventListener("DOMContentLoaded", () => {
    new subNav();
});
