import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class EventsList {
    constructor() {
        this.initialize();
        this.eventsListTitles();
    }

    initialize() {}

    eventsListTitles() {
        // Get current page
        const eventlistpage = document.querySelector(".tn-events-listing-page");

        // Page Title
        const eventlistTitle = document.querySelector(".tn-heading");

        if (eventlistpage) {
            eventlistTitle.textContent = "Events List";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new EventsList();
});
