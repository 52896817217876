import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Checkout {
    constructor() {
        this.initialize();
        this.checkoutTitles();
    }

    initialize() {}

    checkoutTitles() {
        // Get current page
        const checkoutpage = document.querySelector(".tn-payment-page");

        // Page Title
        const headingTitle = document.querySelector(".tn-heading");

        if (checkoutpage) {
            headingTitle.textContent = "Checkout";
        }

        //Billing info title
        const billingTitle = document.querySelector(
            ".tn-patron-billing-information h2",
        );

        if (billingTitle) {
            billingTitle.textContent = "Billing details";
        }

        // Ticket delivery title
        const deliveryTitle = document.querySelector(
            ".tn-patron-shipping-information h2",
        );

        if (deliveryTitle) {
            deliveryTitle.textContent = "Ticket delivery";
        }

        // Basket Titles
        const basketblockTitle = document.querySelector(
            ".tn-cart-component h2",
        );

        if (basketblockTitle) {
            basketblockTitle.textContent = "Basket";
        }

        const itemsTitle = document.querySelector(".tn-cart__section h3");

        if (itemsTitle) {
            itemsTitle.textContent =
                "Your current basket contents are listed below:";
        }

        // Buy now button
        const buynowTitle = document.querySelector("#tn-payment-submit-button");

        if (buynowTitle) {
            buynowTitle.textContent = "Checkout";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Checkout();
});
