import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Memberships {
    constructor() {
        this.initialize();
        this.MembershipsTitles();
    }

    initialize() {}

    MembershipsTitles() {
        // Add title to membership form
        const newTitle = document.createElement("h2");
        newTitle.textContent = "Review your payment";

        const beforeLabel = document.querySelector(
            '[data-control-group-for="AddContribution.Amount"]',
        );

        if (beforeLabel) {
            beforeLabel.parentNode.insertBefore(newTitle, beforeLabel);
        }

        // Add title to membership form review details
        const newReviewTitle = document.createElement("h4");
        newReviewTitle.textContent = "Review your payment";

        const beforereviewLabel = document.querySelector(
            ".form-group.has-feedback",
        );

        if (beforereviewLabel) {
            beforereviewLabel.parentNode.insertBefore(
                newReviewTitle,
                beforereviewLabel,
            );
        }

        // Add title to membership form terms

        const beforetermsLabel = document.querySelector(
            '[data-control-group-for="CT_42_Programme_Name_Text"]',
        );

        if (beforetermsLabel) {
            const newtermsTitle = domHelpers.insertElementAfter(
                beforetermsLabel,
                {
                    element: "h4",
                    className: "terms-title",
                },
            );

            newtermsTitle.textContent = "Terms and conditions";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Memberships();
});
