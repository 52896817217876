/**
 * Wrap an element with another element
 * @param {HTMLElement|NodeList|Array} elements The element or elements to wrap
 * @param {HTMLElement|Object} wrapper The element to wrap the element with or an object with the
 *  element type and class name
 * @returns {HTMLElement}
 */
export function wrapEach(elementsToWrap, wrapper) {
    let wrapperEl;
    let elements = elementsToWrap;

    if (elements instanceof HTMLElement) {
        elements = [elements];
    } else if (NodeList.prototype.isPrototypeOf(elements)) {
        elements = Array.from(elements);
    } else {
        elements = elements;
    }

    if (elements.length === 0) return;

    if (wrapper instanceof HTMLElement) {
        wrapperEl = wrapper;
    } else if (typeof wrapper === "object" && wrapper.element) {
        wrapperEl = document.createElement(wrapper.element);

        if (wrapper.className) {
            wrapperEl.classList.add(wrapper.className);
        }
    }

    if (!(wrapperEl instanceof HTMLElement)) {
        return;
    }

    elements.forEach((element) => {
        element.parentNode.insertBefore(wrapperEl, element);
        wrapperEl.appendChild(element);
    });

    return wrapperEl;
}

/**
 * Wrap elements with another element
 * @param {HTMLElement|NodeList|Array} elementsToWrap The element or elements to wrap
 * @param {HTMLElement|Object} wrapper The element to wrap the elements with or an object with the
 *  element type and class name
 * @returns {HTMLElement}
 */
export function wrapAll(elementsToWrap, wrapper) {
    let wrapperEl;
    let elements = elementsToWrap;

    // Convert elementsToWrap to an array of elements
    if (elements instanceof HTMLElement) {
        elements = [elements];
    } else if (NodeList.prototype.isPrototypeOf(elements)) {
        elements = Array.from(elements);
    }

    if (elements.length === 0) return;

    // Create or use the provided wrapper element
    if (wrapper instanceof HTMLElement) {
        wrapperEl = wrapper;
    } else if (typeof wrapper === "object" && wrapper.element) {
        wrapperEl = document.createElement(wrapper.element);

        if (wrapper.className) {
            wrapperEl.classList.add(wrapper.className);
        }
    }

    if (!(wrapperEl instanceof HTMLElement)) {
        return;
    }

    // Get the parent node of the first element
    const parent = elements[0].parentNode;

    // Insert the wrapper before the first element
    parent.insertBefore(wrapperEl, elements[0]);

    // Append all elements to the wrapper
    elements.forEach((element) => {
        wrapperEl.appendChild(element);
    });

    return wrapperEl;
}

/**
 * Insert a new element after a reference element
 * @param {HTMLElement} referenceElement The existing element after which the new element will be inserted
 * @param {HTMLElement|Object|string} newElement The element to be inserted or an object with the
 *  element type and class name or an HTML string
 * @returns {HTMLElement|null} The inserted element, or null if the insertion failed
 */
export function insertElementAfter(referenceElement, newElement) {
    let newEl;

    if (newElement instanceof HTMLElement) {
        newEl = newElement;
    } else if (typeof newElement === "string") {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = newElement.trim();
        newEl = tempDiv.firstChild;
    } else if (typeof newElement === "object" && newElement.element) {
        newEl = document.createElement(newElement.element);

        if (newElement.className) {
            newEl.classList.add(newElement.className);
        }
    }

    if (!(newEl instanceof HTMLElement)) {
        return null;
    }

    const parentElement = referenceElement.parentNode;

    if (parentElement) {
        if (referenceElement.nextSibling) {
            parentElement.insertBefore(newEl, referenceElement.nextSibling);
        } else {
            parentElement.appendChild(newEl);
        }
    } else {
        return null;
    }

    return newEl;
}
