import { quantityInputWrap } from "../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../scripts/utils/domHelpers.js";

class General {
    constructor() {
        this.initialize();
        this.move_promo();
        this.wrapHeader();
        this.paymentLabel();
        this.requiredLabel();
    }

    initialize() {}

    move_promo() {
        // Select the source promo box inside .tn-subnav-component
        const promoBox = document.querySelector('.tn-subnav-component .tn-promo-box');

        // Select the destination container where you want to move the promo box
        const mainContainer = document.querySelector('.tn-event-detail__main-container');

        // Insert the promo box at the top if both elements exist
        if (promoBox && mainContainer) {
            mainContainer.insertBefore(promoBox, mainContainer.firstChild); // Insert promoBox as the first child of mainContainer
        }
    }

    wrapHeader() {
        const headerElement = document.getElementById("tn-page-heading");

        if (!headerElement) {
            return;
        }

        const h1Container = domHelpers.wrapEach(headerElement, {
            element: "div",
            className: "container",
        });

        const headingContainer = domHelpers.wrapEach(h1Container, {
            element: "div",
            className: "tn-heading-container",
        });

        const pages = document.querySelectorAll(
            `.tn-events-listing-page, 
            .tn-cart-details-page, 
            .tn-account-login-page, 
            .tn-cart-details-page, 
            .tn-events-detail-page,
            .tn-account-create-page,
            .tn-account-create-brief-page, 
            .tn-payment-page, 
            .tn-account-update-page,
            .tn-account-interests-page,
            .tn-account-memberships-page,
            .tn-upcoming-events-page,
            .tn-contribution-individual-page,
            .tn-error-page, 
            .tn-shipping-page`,
        );

        pages.forEach((page) => {
            page.parentNode.insertBefore(headingContainer, page);
        });
    }

    paymentLabel() {
        // Get current page
        const Signuppage = document.querySelector(
            ".tn-account-create-brief-page",
        );

        // Page Title
        const paymentlabelTitle = document.querySelector(
            ".tn-amount-field-label",
        );

        if (paymentlabelTitle) {
            paymentlabelTitle.textContent = "Payment of";
        }
    }

    requiredLabel() {
        // Page Title
        const requiredlabelTitle = document.querySelector(".pull-right");

        if (requiredlabelTitle) {
            requiredlabelTitle.textContent = "(required)";
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const element = document.querySelectorAll(".tn-heading");
    new General();
});
