import { quantityInputWrap } from "../../scripts/utils/quantityInputWrap.js";
import * as domHelpers from "../../scripts/utils/domHelpers.js";

class Login {
    constructor() {
        this.initialize();
        this.loginTitle();
        this.RegisterButtonTitle();
        this.movefooterBottom();
    }

    initialize() {}

    loginTitle() {
        const LoginPage = document.querySelector(".tn-account-login-page");
        const loginTitle = document.querySelector(".tn-login-component h2");

        if (LoginPage) {
            loginTitle.textContent = "Sign in to your account";
        }
    }

    RegisterButtonTitle() {
        const RegisterbuttonTitle = document.querySelector(
            ".tn-register-button",
        );

        if (RegisterbuttonTitle) {
            RegisterbuttonTitle.textContent = "Create an account";
        }
    }

    movefooterBottom() {
        // Move Dates Container
        const additionalDates = document.querySelector(".tn-page-bottom");

        const container = document.querySelector("#tn-login-form");

        if (container) {
            container.append(additionalDates);
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new Login();
});
